<template>
  <div class="sale-wrapper bj">
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="from1"
      @Refresh="Refresh"
      @search="search"
    >
      <template v-slot:right>
        <el-button type="warning" @click="allclick">批量修改</el-button>
        <el-button
          @click="onHandleExport"
          type="warning"
          icon="el-icon-upload2"
          :disabled="disabledExport"
          >导出</el-button
        >
      </template>
    </Filtersearch>

    <div class="auto-table-flex">
      <tablecolumn
        ref="tablecolumnref"
        :loadingchange="loading"
        :tableDatalist="tableData"
        :tablecolumn="tablecolumn"
        @tablechange="tablechange"
        @Selection="Selection"
      >
        <template v-slot:dslot>
          <el-table-column
            label="操作"
            fixed="right"
            align="center"
            min-width="130"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="editDialogclcik(scope.row)"
                >编辑</el-button
              >

              <el-button type="text" @click="changeRecordclcik(scope.row)">查看日志</el-button>
            </template>
          </el-table-column>
        </template>
      </tablecolumn>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <editDialog
      ref="editDialogref"
      @tablechangeupdate="tablechangeupdate"
    ></editDialog>
    <changeRecord
      ref="changeRecordref"
    ></changeRecord>
  </div>
</template>
  <script>
import Filtersearch from "@/components/Filtersearch.vue";
import tablecolumn from "@/components/table-column.vue";
import editDialog from "./modules/editDialog.vue";
import changeRecord from "./modules/changeRecord.vue";
import { paymentconfigexport } from "@/api/export/index.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "supplier",
  components: {
    Filtersearch,
    tablecolumn,
    editDialog,
    changeRecord
  },
  data() {
    return {
      currentPage: 0,
      total: 0,
      loading: false,
      disabledExport: false, // 导出按钮
      tableData: [],
      from: {
        page: 1,
        pageSize: 50,
        logistics_business_id: "549", //	number	集配中心id
        logistics_id: "", //	string	筛选项-城市仓id
        merchant_code: "", //	string	筛选项-费率类型
        payment_edit_start_time: "", //	string	筛选项-操作开始时间
        payment_edit_end_time: "", //	string	筛选项-操作结束时间
        op_name: "", //	string	筛选项-操作人
        payment_edit: [],
      },
      from1: "",
      Selectionlist: [],
      Filtersearchlist: [
        {
          type: "select",
          name: "城市仓",
          clearable: true,
          filterable: true,
          model: "logistics_id",
          placeholder: "请选择城市仓",
          label: "name",
          value: "id",
          selectoption: [],
          selectoptionname: "logisticsList",
        },
        {
          type: "select",
          name: "费率类型",
          clearable: true,
          model: "merchant_code",
          placeholder: "请选择费率类型",
          label: "value",
          value: "key",
          selectoption: [],
          selectoptionname: "paymentConfigList",
        },
        {
          type: "daterange",
          name: "操作时间",
          clearable: true,
          model: "payment_edit",
          startmodel: "payment_edit_start_time",
          endmodel: "payment_edit_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "input",
          name: "操作人",
          clearable: true,
          model: "op_name",
          placeholder: "请输入操作人",
        },
      ],
      tablecolumn: [
        {
          type: "selection",
        },
        {
          prop: "name",
          label: "类型",
          width: "",
        },
        {
          prop: "id",
          label: "城市仓ID",
          width: "",
        },
        {
          prop: "statusName",
          label: "城市仓状态",
          width: "",
        },
        {
          prop: "merchantName",
          label: "启用中费率类型",
          width: "",
        },
        {
          prop: "paymentEditDate",
          label: "操作时间",
          width: "",
        },
        {
          prop: "opName",
          label: "操作人",
          width: "",
        },

        {
          prop: "name",
          label: "操作",
          slot: "dslot",
          fixed: "left",
          width: "130",
        },
      ],
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getsearch();
  },
  watch: {},
  methods: {
    allclick() {
      if (this.Selectionlist.length > 0) {
        let obj = {
          logistics_ids: [],
          name: "",
        };
        this.Selectionlist.forEach((el) => {
          obj.logistics_ids.push(el.id);
          obj.name = obj.name + el.name + ",";
        });
        this.$refs.editDialogref.onInitDataAll(obj, 549);
      } else {
        this.$message.error("请选择一条数据");
      }
    },
    editDialogclcik(e) {
      this.$refs.editDialogref.onInitData(e, 549);
    },
    changeRecordclcik(e) {
      this.$refs.changeRecordref.onInitData(e,549);
    },
    tablechangeupdate(e) {
      this.hqlist();
    },
    /**
     * 导出逻辑
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;

        const res = await paymentconfigexport(query);
        downLoadFlowBlob(res, res?.filename);
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getAfterSaleNewUnExport err", err);
        console.log("ajax postPurchaseSettleExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },

    getsearch() {
      this.$api.payment
        .configsearch({ logistics_business_id: 549 })
        .then((res) => {
          this.Filtersearchlist.forEach((el) => {
            if (el.type == "select" && el.selectoptionname) {
              let obj = { [el.value]: "", [el.label]: "全部" };
              el.selectoption = [obj, ...res.data[el.selectoptionname]];
            }
          });
        });
    },
    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.hqlist();
    },
    columnwidth(e) {
      let width = "";
      this.tablecolumn.forEach((el) => {
        if (el.prop == e) {
          width = el.width ? el.width : "";
        }
      });
      return width;
    },
    // tablechange(e) {
    //   this.tablecolumn = e;
    // },
    tablechange() {
      this.hqlist();
    },
    Selection(e) {
      this.Selectionlist = e;
      console.log(e, "选中了那些东西");
    },
    search(e) {
      this.from.page = 1;
      this.currentPage = 1;
      this.from = e;
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.payment.productlist(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
  <style lang="scss">
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
  